import { serviceApi } from '@Src/store/api';
import { neededCookies } from '@Src/hooks/useCookies';
import { ApiTags, isOffline, LSKeys } from '@Src/constants';
import { getMeReturnType } from '@State/auth/types';

export const authApiSlice = serviceApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      queryFn: async ({ username, password }, _, __, fetchWithBQ) => {
        const { data } = await fetchWithBQ({
          url: '/users/login',
          method: 'post',
          data: { username, password },
          skipCookieCheck: true,
        });

        return { data };
      },
    }),

    getMe: builder.query<getMeReturnType, null>({
      keepUnusedDataFor: 0,
      queryFn: async (_, __, ___, fetchWithBQ) => {
        if (isOffline()) {
          if (localStorage.getItem(LSKeys.me) !== null) {
            const data = JSON.parse(localStorage.getItem(LSKeys.me)!);
            return { data };
          }
          return {
            error: {
              status: 500,
              data: 'App offline e dati utente non presenti in storage',
            },
          };
        }

        try {
          const {
            data: {
              operatore, superoperatore, cliente, status, message,
            },
          } = await fetchWithBQ({
            url: '/session',
            method: 'get',
          });

          if (status >= 400) {
            return {
              error: {
                status: 500,
                data: message,
              },
            };
          }

          localStorage.setItem(LSKeys.me, JSON.stringify({ operatore, superoperatore, cliente }));

          return { data: { operatore, superoperatore, cliente } };
        } catch (e) {
          return {
            error: {
              status: 500,
              data: 'Errore in getMe',
            },
          };
        }
      },
      providesTags: [ApiTags.me],
    }),

    logout: builder.mutation<null, null>({
      query: () => ({
        url: '/users/logout',
        method: 'post',
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          Object.keys(neededCookies).forEach((c) => deleteCookie(c));
        } catch (e) {
          console.error(e);
        }
      },
    }),
  }),
});

function deleteCookie(name: string) {
  if (getCookie(name)) {
    const cookieDomains: string | undefined = process.env.REACT_APP_COOKIE_DOMAINS;

    if (cookieDomains) {
      cookieDomains.split('~').forEach((domain: string) => {
        document.cookie = `${name}=;Path=/;Domain=${domain};Expires=${new Date('1970-01-01T00:00:01Z').toUTCString()}`;
      });
    } else {
      document.cookie = `${name}=;Path=/;Expires=${new Date('1970-01-01T00:00:01Z').toUTCString()}`;
    }
  }
}

function getCookie(name: string) {
  return document.cookie.split(';').some((c) => c.trim().startsWith(`${name}=`));
}
