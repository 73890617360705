import { configureStore } from '@reduxjs/toolkit';
import { serviceApi } from '@Store/api';
import persistApiMiddleware from '@Store/middlewares';
import rootReducer from './reducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(serviceApi.middleware, persistApiMiddleware),
  devTools: { name: 'fgp-pwa' },
});

export type RootState = ReturnType<typeof store.getState>;
export { store };
