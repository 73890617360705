import { isFulfilled } from '@reduxjs/toolkit';
import { getCatalogInputType, CatalogItem } from '@State/catalog/types';
import catalogApiSlice from '@State/catalog';
import { store } from '@Store/configureStore';

const PERSISTED_API_KEY = 'persisted_catalog_cache';

// @ts-expect-error any
const persistApiMiddleware = () => (next) => (action) => {
  if (isFulfilled(action) && catalogApiSlice.endpoints.getCatalog.matchFulfilled(action)) {
    try {
      const params = action.meta.arg.originalArgs as unknown as getCatalogInputType;
      const payload = action.payload as CatalogItem[];

      if (params.page) {
        const pageKey = String(params.page);

        const existingCache = JSON.parse(localStorage.getItem(PERSISTED_API_KEY) || '{}');

        localStorage.setItem(
          PERSISTED_API_KEY,
          JSON.stringify({
            ...existingCache,
            [pageKey]: payload,
          }),
        );
      }
    } catch (error) {
      console.error('Errore nel salvataggio della cache API:', error);
    }
  }

  return next(action);
};

const hydrateApiCache = async () => {
  try {
    const cachedData: Record<number, CatalogItem[]> = JSON.parse(localStorage.getItem(PERSISTED_API_KEY) || '{}');

    await Promise.all(
      Object.entries(cachedData).map(
        ([page, data]) => store.dispatch(
          catalogApiSlice.util.upsertQueryData<'getCatalog'>(
            'getCatalog',
            { page },
            data,
          ),
        ),
      ),
    );
  } catch (error) {
    console.error('Errore durante l’idratazione della cache API:', error);
  }
};

export default persistApiMiddleware;
export { hydrateApiCache };
