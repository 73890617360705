let request: IDBOpenDBRequest;
let db: IDBDatabase;

const DB_NAME = 'FGP';
const UNKNOWN_ERROR = 'Unknown error';

export const Stores = {
  agenti: {
    name: 'agenti',
    keyPath: 'tb_codcage',
  },
  clienti: {
    name: 'clienti',
    keyPath: 'an_conto',
  },
  catalogo: {
    name: 'catalogo',
    keyPath: 'pagina',
  },
};

const initDB = (): Promise<boolean> => new Promise((resolve) => {
  request = indexedDB.open(DB_NAME);

  request.onsuccess = () => {
    db = request.result;

    const missingStores = Object.values(Stores).filter((store) => !db.objectStoreNames.contains(store.name));

    if (missingStores.length > 0) {
      const newVersion = db.version + 1;
      db.close();

      const upgradeRequest = indexedDB.open(DB_NAME, newVersion);

      upgradeRequest.onupgradeneeded = () => {
        const upgradeDB = upgradeRequest.result;

        missingStores.forEach((store) => {
          if (!upgradeDB.objectStoreNames.contains(store.name)) {
            upgradeDB.createObjectStore(store.name, { keyPath: store.keyPath });
          }
        });
      };

      upgradeRequest.onsuccess = () => {
        resolve(true);
      };

      upgradeRequest.onerror = () => {
        resolve(false);
      };
    } else {
      resolve(true);
    }
  };

  request.onerror = () => {
    resolve(false);
  };
});

const addData = <T>(storeName: string, data: T): Promise<T|string|null> => new Promise((resolve) => {
  request = indexedDB.open(DB_NAME);

  request.onsuccess = () => {
    db = request.result;
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);
    if (Array.isArray(data)) {
      data.map((d) => {
        store.add(d);
        return null;
      });
    } else {
      store.add(data);
    }
    resolve(data);
  };

  request.onerror = () => {
    const error = request.error?.message;
    console.error(error);
    if (error) {
      resolve(error);
    } else {
      resolve(UNKNOWN_ERROR);
    }
  };
});

const getData = <T>(storeName: string): Promise<T[]> => new Promise((resolve, reject) => {
  request = indexedDB.open(DB_NAME);

  request.onsuccess = () => {
    try {
      db = request.result;
      const tx = db.transaction(storeName, 'readonly');
      const store = tx.objectStore(storeName);
      const res = store.getAll();
      res.onsuccess = () => {
        resolve(res.result);
      };
    } catch (e) {
      reject(Error(`Error: no ${storeName} store found`));
    }
  };
});

const updateData = <T>(storeName: string, id: string, data: T): Promise<T|string|null> => new Promise((resolve) => {
  request = indexedDB.open(DB_NAME);

  request.onsuccess = () => {
    db = request.result;
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);
    const getRequest = store.get(id);

    getRequest.onsuccess = () => {
      if (getRequest.result) {
        store.put(data);
        resolve(data);
      } else {
        resolve('No item found with the specified ID.');
      }
    };

    getRequest.onerror = () => {
      resolve(`Error fetching item: ${getRequest.error?.message || 'Unknown error'}`);
    };
  };

  request.onerror = () => {
    const error = request.error?.message;
    if (error) {
      resolve(error);
    } else {
      resolve(UNKNOWN_ERROR);
    }
  };
});

const deleteData = (storeName: string, key: string): Promise<boolean> => new Promise((resolve) => {
  request = indexedDB.open(DB_NAME);

  request.onsuccess = () => {
    db = request.result;
    const tx = db.transaction(storeName, 'readwrite');
    const store = tx.objectStore(storeName);
    const res = store.delete(key);

    res.onsuccess = () => {
      resolve(true);
    };
    res.onerror = () => {
      resolve(false);
    };
  };
});

const clearStore = (storeName: string): Promise<boolean> => new Promise((resolve) => {
  request = indexedDB.open(DB_NAME);

  request.onsuccess = () => {
    db = request.result;

    // Avvia una transazione in modalità readwrite
    const transaction = db.transaction([storeName], 'readwrite');

    // Ottieni lo store che vuoi svuotare
    const objectStore = transaction.objectStore(storeName);

    // Cancella tutte le righe dallo store
    const clearRequest = objectStore.clear();

    clearRequest.onerror = (event) => {
      console.error('Error:', event.target);
    };

    transaction.onerror = (event) => {
      console.error('Tx Error:', event.target);
    };

    resolve(true);
  };

  request.onerror = () => {
    resolve(false);
  };
});

export {
  initDB,
  addData,
  getData,
  updateData,
  deleteData,
  clearStore,
};
