import { serviceApi } from '@Store/api';
import { CatalogItem, getCatalogInputType } from '@State/catalog/types';

export const catalogApiSlice = serviceApi.injectEndpoints({
  endpoints: (builder) => ({

    getCatalog: builder.query<CatalogItem[], getCatalogInputType>({
      query: ({ page }) => ({
        url: `/catalog/${page}`,
        method: 'get',
      }),
      transformResponse: (response) => response.content.catalog as CatalogItem[],
    }),

  }),
});
