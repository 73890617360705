import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { store } from '@Store/configureStore';
import { hydrateApiCache } from '@Store/middlewares';
import Router from '@Src/router';

const runApp = async () => {
  await hydrateApiCache();

  const root = createRoot(
    document.getElementById('root') as HTMLElement,
  );

  root.render(
    <Provider store={store}>
      <Suspense fallback={<div />}>
        <Router />
      </Suspense>
    </Provider>,
  );
};

runApp();
